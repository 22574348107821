<template>
  <div class="text-center w-100 mb-6">
    <TTBtn
      fab
      depressed
      color="white"
      elevation="1"
      large
      class="mb-2"
      data-test="aside-right-btn-template-list"
      @click="$root.$emit('editTutorChecklist')"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-edit
      </VIcon>
    </TTBtn>
    <div class="tt-text-caption tt-black--text text--lighten-2">
      Редактир.<br>
      чек-лист
    </div>
  </div>
</template>

<script>
import * as names from '@/plugins/vue-router/constants';

export default {
  name: 'AppReportTutorAsideRight',
  data: () => ({
    names,
  }),
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
